import { useEffect, useMemo, FC } from "react";

import { Text } from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  NumberOfCondition,
  NumberOperator,
  numberOfOperatorOptions,
  initialPropertyCondition,
  AudienceParent,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { AddBoxSVG } from "src/ui/icons/new-icons";
import { NewSelect } from "src/ui/new-select";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterPopover } from "../filter-popover";
import { removePropertySubcondition, updatePropertySubcondition } from "../utils/condition-builders";
import { formatSubconditions } from "../utils/format-subconditions";
import { isAndOrCondition } from "../utils/type-guards";
import { AmountInput } from "./amount-input";
import { FilterProps, HStack } from "./condition";
import { DetailButton } from "./condition-buttons";
import { validateNumberOfCondition } from "./condition-validation";
import { Filter } from "./filter";
import { NestedPropertyFilter } from "./nested-property-filter";

const RelatedTo = {
  operator: NumberOperator.GreaterThan,
  value: 1,
};

const NotRelatedTo = {
  operator: NumberOperator.Equals,
  value: 0,
};

const relatedOptions = [
  { label: "Has", value: "related" },
  { label: "Does not have", value: "not related" },
];

export const NumberOfFilter: FC<Readonly<FilterProps<NumberOfCondition>>> = (props) => {
  const { relationships: relationshipsOverride, condition: unformattedCondition, onChange } = props;

  const { relationships: allRelationships } = useQueryBuilderContext();

  const relationships = relationshipsOverride ?? allRelationships;

  // Wrap subconditions in one and/or condition
  const condition = formatSubconditions(unformattedCondition);
  const topLevelSubcondition = condition.subconditions?.[0];
  // Assumption is that event condition will be formatted to always have one And/Or subcondition as a child (if there are subconditions)
  const subconditions = isAndOrCondition(topLevelSubcondition) ? topLevelSubcondition.conditions : [];

  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.relationshipId;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validateNumberOfCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.relationshipId, condition.value, filterId]);

  const relatedModel = relationships?.find(({ id }) => id === condition.relationshipId)?.to_model;

  const isNotRelated = condition.value === 0 && condition.operator === NumberOperator.Equals;
  const relatedValue = isNotRelated ? "not related" : "related";
  const relatedLabel = relatedOptions?.find(({ value }) => relatedValue === value)?.label;
  const operatorLabel = numberOfOperatorOptions.find(({ value }) => value === condition.operator)?.label;

  const updateWrappingConditionType = (type: ConditionType.And | ConditionType.Or) => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: type,
          conditions: topLevelSubcondition?.conditions ?? [],
        },
      ],
    });
  };

  const addSubcondition = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type,
          conditions: [...topLevelSubcondition.conditions, initialPropertyCondition],
        },
      ],
    });
  };

  const updateSubcondition = (updates: Partial<PropertyCondition>, index: number) => {
    onChange(updatePropertySubcondition(index, condition, updates));
  };

  const removeSubcondition = (index: number) => {
    onChange(removePropertySubcondition(index, condition));
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Filter
          content={
            <>
              <NewSelect
                error={Boolean(valueError)}
                options={relatedOptions}
                placeholder="Select relation"
                sx={{ flex: "0 0 auto" }}
                value={relatedValue}
                width={140}
                onChange={(value) => {
                  if (value === "related") {
                    onChange(RelatedTo);
                  } else {
                    onChange(NotRelatedTo);
                  }
                }}
              />
              {isNotRelated ? null : (
                <>
                  <NewSelect
                    options={numberOfOperatorOptions}
                    placeholder="Filter on"
                    sx={{ flex: "0 0 auto" }}
                    value={condition.operator}
                    width={200}
                    onChange={(operator) => onChange({ operator })}
                  />
                  <AmountInput
                    sx={{ width: "100px" }}
                    type="number"
                    value={condition.value}
                    onChange={(value) => {
                      onChange({ value });
                    }}
                  />
                </>
              )}
            </>
          }
          error={valueError}
        >
          <Text fontWeight="medium">
            <Text color="text.secondary" fontWeight="normal">
              {relatedLabel}
            </Text>
            {!isNotRelated && ` ${operatorLabel} ${condition.value}`}
          </Text>
        </Filter>
        <Text color="text.secondary" mt={1.5}>
          record in
        </Text>
        <FilterPopover {...props} condition={condition} hasError={Boolean(modelError)} />
      </HStack>
      {subconditions.map((subcondition, index) => {
        if (subcondition.type !== ConditionType.Property) {
          return null;
        }

        return (
          <NestedPropertyFilter
            key={index}
            {...props}
            columns={relatedModel?.filterable_audience_columns}
            condition={subcondition}
            isFirstCondition={index === 0}
            isWrappedWithAndCondition={topLevelSubcondition?.type === ConditionType.And}
            // this is okay since we only use property filters
            parent={relatedModel as unknown as AudienceParent}
            traits={[]}
            onChange={(updates) => updateSubcondition(updates, index)}
            onRemove={() => removeSubcondition(index)}
            onToggleWrappingConditionType={() =>
              updateWrappingConditionType(
                topLevelSubcondition?.type === ConditionType.And ? ConditionType.Or : ConditionType.And,
              )
            }
          />
        );
      })}
      <Row pl={10}>
        <DetailButton icon={AddBoxSVG} isDisabled={!condition.relationshipId} size="sm" onClick={addSubcondition}>
          Where...
        </DetailButton>
      </Row>
    </>
  );
};
