import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FolderOpenIcon, TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  Column,
  Pill,
  Heading,
  Row,
  SearchInput,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
  Text,
  Box,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";

import modelPlaceholder from "src/assets/placeholders/model.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  modelQueryTypeFilterConfig,
  sourceTypeFilterConfigForModels,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { isFilterActive } from "src/components/folders/utils";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToModelsMutation,
  useDeleteModelsMutation,
  useDraftsQuery,
  useModelFiltersQuery,
  useModelsCountQuery,
  useModelsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { FolderIcon } from "src/ui/icons";
import { PageTable, TableColumn, useTableConfig, useTableSort, OrderBy, SortOption } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { QueryTypeIcon } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof SegmentsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SegmentsOrderBy>[] = [
  { key: "query_type", direction: OrderBy.Desc, label: "Model type" },
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "syncs_aggregate.count" as any, direction: OrderBy.Desc, label: "Number of syncs" },
  { key: "last_run_size", direction: OrderBy.DescNullsLast, label: "Largest" },
  { key: "last_run_size", direction: OrderBy.AscNullsLast, label: "Smallest" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

export const Models: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const { mutateAsync: bulkDelete } = useDeleteModelsMutation();
  const [addingLabels, setAddingLabels] = useState(false);
  const { selectedFolder, setSelectedFolder, setMovingToFolder, movingToFolder, header, refetchFolders } = useFolderState({
    search,
    resourceType: "models",
    folderType: "models",
  });

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanUpdate } = useHasPermission([{ resource: "model", grants: [ResourcePermissionGrant.Update] }]);

  const { limit, offset, page, setPage } = useTableConfig<SegmentsOrderBy>();

  const orderBy = useTableSort<SegmentsOrderBy>(initialSort, sortOptions);

  const { labels } = useLabels();

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToModelsMutation();

  const { data: modelFilters, isLoading: filtersLoading } = useModelFiltersQuery(undefined, {
    select: (data) => data.segments,
  });

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "models",
      loading: filtersLoading,
      filters: {
        type: { options: modelQueryTypeFilterConfig(modelFilters || []), title: "Query type" },
        source_type: {
          options: sourceTypeFilterConfigForModels(modelFilters || []),
          title: "Source type",
        },
        created: { options: createdByFilterConfig(modelFilters || []), title: "Created by" },
        label: { options: labelFilterConfig(modelFilters || []), title: "Labels" },
        duplicate_keys: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Duplicate primary keys",
        },
      },
    };
  }, [modelFilters]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, selectView, updateCurrentView, resetViewFilters, clearFilters },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!modelFilters?.length) {
      return {};
    }

    const folderFilter = (): SegmentsBoolExp => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      if (folderIds.length) {
        return {
          folder_id: { _in: folderIds },
        };
      } else {
        return {};
      }
    };

    const duplicateKeyFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.duplicate_keys)) {
        if (filterState.duplicate_keys.selected.length !== 1) {
          return {};
        }
        if (filterState.duplicate_keys.selected.map((f) => f.id)[0] === "true") {
          return {
            last_run_duplicate_primary_keys: { _gt: 0 },
          };
        }
        if (filterState.duplicate_keys.selected.map((f) => f.id)[0] === "false") {
          return {
            _or: [{ last_run_duplicate_primary_keys: { _eq: 0 } }, { last_run_duplicate_primary_keys: { _is_null: true } }],
          };
        }
      }
      return {};
    };

    const queryTypeFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.type)) {
        return {
          query_type: { _in: filterState.type.selected.map((f) => f.id) },
        };
      }
      return {};
    };

    const sourceTypeFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.source_type)) {
        return {
          connection: {
            type: { _in: filterState.source_type.selected.map((f) => f.id) },
          },
        };
      }
      return {};
    };

    const labelFilter = () => {
      if (isFilterActive(filterState.label)) {
        return {
          _or: filterState.label.selected.map((filter) => {
            const key = filter.id.split(":")[0];
            const value = filter.id.split(":")[1];
            const obj = {};
            obj[key!] = value;
            return {
              tags: { _contains: obj },
            };
          }),
        };
      }
      return {};
    };

    const createdFilter = () => {
      if (isFilterActive(filterState.created)) {
        return {
          _or: [
            {
              created_by: { _in: filterState.created.selected.map((f) => f.id) },
            },
            {
              created_by: { _is_null: true },
            },
          ],
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...queryTypeFilter(),
        ...sourceTypeFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SegmentsBoolExp = {
      ...andFilter(),
      ...folderFilter(),
      ...labelFilter(),
      ...createdFilter(),
      ...duplicateKeyFilter(),
    };

    return hasuraFilters;
  }, [search, selectedFolder, filterState]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (search) {
      return { ...hasuraFilters, name: { _ilike: `%${search}%` } };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, search]);

  const modelsQuery = useModelsQuery(
    {
      offset,
      limit,
      filters: { ...hasuraFiltersWithSearch, is_schema: { _eq: false }, query_type: { _neq: "visual" } },
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { data: allModelsCount } = useModelsCountQuery({
    filters: {
      query_type: { _neq: "visual" },
      is_schema: { _eq: false },
    },
  });

  const { data: drafts } = useDraftsQuery({
    resourceType: "model",
    status: "pending",
  });

  const models = modelsQuery.data?.segments;
  const modelsCount = modelsQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a model, upgrade your plan.";

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);

      toast({
        id: "bulk-add-model-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "model",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-model-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDeleteModels = async () => {
    if (userCanDelete) {
      await bulkDelete({ ids: selectedRows.map(String) });
      onRowSelect([]);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: ({ id, name, connection: source, draft: isInitialDraft, query_runs, query_type, matchboosting_enabled }) => {
          const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));

          return (
            <Row gap={3} overflow="hidden" width="100%" align="center">
              <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name} />
              <Column gap={1} overflow="hidden">
                <Row gap={2} align="center">
                  <Text isTruncated fontWeight="medium">
                    {name ?? "Private model"}
                  </Text>
                  {isInitialDraft && <DraftBadge />}
                  {draft && <DraftIcon draft={draft} />}
                </Row>

                <Row gap={2}>
                  <Pill>
                    <Row gap={2} align="center">
                      <QueryTypeIcon type={query_type} />
                      {query_runs?.[0] ? `${abbreviateNumber(query_runs?.[0]?.size)} rows` : "Unknown size"}
                    </Row>
                  </Pill>
                  {matchboosting_enabled && (
                    <Pill>
                      <Row gap={2} align="center">
                        <SparklesIcon color="#F5C24D" width={16} />
                        Boosted
                      </Row>
                    </Pill>
                  )}
                </Row>
              </Column>
            </Row>
          );
        },
      },
      {
        name: "Syncs",
        max: "max-content",
        min: "232px",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        breakpoint: "md",
      },
      {
        name: "Folder",
        key: "folder",
        max: ".5fr",
        cell: (folder) => {
          if (folder) {
            return (
              <Row gap={2} align="center" overflow="hidden">
                <FolderIcon width="16px" />
                <Text isTruncated fontWeight="medium">
                  {folder.name}
                </Text>
              </Row>
            );
          }
          return "--";
        },
        breakpoint: "md",
      },
      {
        name: "Labels",
        key: "tags",
        max: ".5fr",
        cell: (tags) => {
          if (isEmpty(tags)) {
            return "--";
          }
          return <Labels labels={tags} />;
        },
        breakpoint: "lg",
      },
    ],
    [drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/models/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No models found",
      error: "Models failed to load, please try again.",
    }),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          sidebar={
            <>
              <Row pr={6} pl={2}>
                <SearchInput
                  placeholder="Search all models..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Row>
              <Column px={2} gap={4} overflow="auto">
                <Folders
                  modelsCount={allModelsCount?.segments_aggregate.aggregate?.count}
                  modelsRootName="All models"
                  refetchFolders={refetchFolders}
                  rootFolder="models"
                  selectedFolder={selectedFolder}
                  setRootFolder={() => undefined}
                  setSelectedFolder={setSelectedFolder}
                  viewType="models"
                />

                <Box borderBottom="1px solid" borderColor="base.divider" />

                <Filters
                  clearFilters={clearFilters}
                  createView={createView}
                  creatingView={creatingView}
                  deleteView={deleteView}
                  filters={filterData}
                  resetFilters={resetViewFilters}
                  resource="model"
                  selectView={selectView}
                  selectedView={selectedView}
                  updateCurrentView={updateCurrentView}
                  updatingView={updatingView}
                  viewNotSaved={viewNotSaved}
                  views={views}
                />
              </Column>
            </>
          }
          title="Models"
        >
          <PageTable
            header={
              <>
                <Heading isTruncated size="xl">
                  {header}
                </Heading>
                <Row flexShrink={0} gap={3}>
                  {selectedRows.length > 0 && (
                    <Row align="center" gap={2}>
                      <Text>{`${pluralize("model", selectedRows.length, true)} selected`}</Text>
                      <Menu>
                        <MenuButton>Actions</MenuButton>
                        <MenuList>
                          {userCanUpdate && (
                            <>
                              <MenuItem
                                icon={FolderOpenIcon}
                                onClick={() => {
                                  setMovingToFolder(true);
                                }}
                              >
                                Move to folder
                              </MenuItem>
                              <MenuItem
                                icon={TagIcon}
                                onClick={() => {
                                  setAddingLabels(true);
                                }}
                              >
                                Add labels
                              </MenuItem>
                            </>
                          )}
                          {userCanUpdate && userCanDelete && <MenuDivider />}
                          {userCanDelete && (
                            <MenuItem
                              icon={TrashIcon}
                              variant="danger"
                              onClick={() => {
                                setConfirmingDelete(true);
                              }}
                            >
                              Delete
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Row>
                  )}
                  <PermissionedLinkButton
                    href="/models/new"
                    isDisabled={overageLockout}
                    permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
                    tooltip={overageLockout && overageText}
                    variant="primary"
                    onClick={() => {
                      analytics.track("Add Model Clicked");
                    }}
                  >
                    Add model
                  </PermissionedLinkButton>
                </Row>
              </>
            }
            columns={columns}
            data={models}
            error={Boolean(modelsQuery.error)}
            loading={modelsQuery.isFetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
            rowHeight="80px"
            pagination={{
              count: modelsCount,
              label: "models",
              page,
              setPage,
            }}
            sortOptions={sortOptions}
          />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="model"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteModels}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="models"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            modelsQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("model", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.model) {
    return <Models />;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={
        resources?.source ? null : (
          <PageAlert
            button={
              <PermissionedLinkButton
                href="/sources/new"
                permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
                variant="primary"
              >
                Configure data source
              </PermissionedLinkButton>
            }
            description="Hightouch must be connected to least one data source before you can create a model. Your source can be a data warehouse, spreadsheet, or other data system."
            title="First, you need to configure a data source"
          />
        )
      }
      title="Models"
    >
      <Heading mb={8} size="xl">
        Models
      </Heading>
      <Placeholder
        content={{
          image: modelPlaceholder,
          title: "No models in this workspace",
          body: "A model describes how your data source will be queried. For most sources, you can use SQL to filter, join, and transform your data before syncing. Alternatively, you can select an existing table or view, or import models from other tools like Looker and dbt.",
          button: resources?.source ? (
            <PermissionedLinkButton
              href="/models/new"
              permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add model
            </PermissionedLinkButton>
          ) : null,
        }}
      />
    </Page>
  );
};

export default Loader;
